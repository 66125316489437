export const advantages = [
  {
    icon: '/images/svg/structure.svg',
    title: 'Structural Geology',
    subtitle:
      'Mapping faults and fractures is a time consuming business, so make sure you have the best tools at your fingertips. Display and analyse your structural data with the integreated stereonet.',
  },
  {
    icon: '/images/svg/sedimentology.svg',
    title: 'Sedimentology',
    subtitle:
      'Make measured sections, integrate field observations or measure geobodies. Create correlation panels and more in VRGS.'  ,
  },
 
];
