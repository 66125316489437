/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import Annotations from 'views/Annotations';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

import SEO from '../src/components/SEO';

export const Head = () => (
  <>
    <SEO />
  </>
);

const AnnotationsPage = () => {
  return <WithLayout component={Annotations} layout={Main} />;
};

export default AnnotationsPage;
